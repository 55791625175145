<template>
    <div class="project-record-detail">
        <div class="card">
            <van-row class="title">
                <van-col span="24">
                    {{data.title}}
                </van-col>
            </van-row>
            <van-row class="info">
                <van-col span="24">
                    授信等级：{{data.level}}
                </van-col>
            </van-row>
            <van-row class="info">
                <van-col span="16">货量（万吨）：{{data.weight}}</van-col>
                <van-col span="8" @click="to(data.lineDetail)">航线明细
                    <van-icon name="share-o"/>
                </van-col>
            </van-row>
            <van-row class="info">
                <van-col span="16">效益（万元）：{{data.benefit}}</van-col>
                <van-col span="8" @click="to(data.planDetail)">出差计划详情
                    <van-icon name="share-o"/>
                </van-col>
            </van-row>
        </div>
        <div>
            <van-row class="info-title">基本信息</van-row>
            <van-field readonly required label="计划日期" :value="info.date"></van-field>
            <van-field readonly required label="目标" :value="info.targt"></van-field>
            <van-field readonly required label="现状" :value="info.presentSituation"></van-field>
            <van-field readonly required label="计划方案选择" :value="info.plan"></van-field>
            <van-field readonly required label="准备怎么做" :value="info.getReady"></van-field>
            <van-field label="备注" :value="info.remarks"></van-field>
        </div>
        <crm-tab-bar/>
    </div>
</template>

<script>
    import CrmTabBar from "@/components/CrmTabBar";

    export default {
        name: "ProjectRecordDetail",
        components: {CrmTabBar},
        data() {
            return {
                data: {
                    title: '中国城通国际贸易有限公司',
                    level: 'B',
                    weight: '12.37万吨',
                    benefit: '7.89万元',
                    lineDetail: 'lineDetail',
                    planDetail: 'planDetail'
                },
                info: {
                    date: '2020-12-12',
                    targt: '目标',
                    presentSituation: '现状',
                    plan: '计划方案选择',
                    getReady: '准备怎么做',
                    remarks: '备注',
                }
            }
        },
        methods: {
            to(to) {
                this.$router.push(to)
            }
        }
    }
</script>

<style scoped>
    .title {
        padding: 10px 5px;
        font-weight: 400;
        font-size: 18px;
        color: #EEEEEE;
        font-style: normal;
        letter-spacing: 0;
        line-height: 27px;
        text-decoration: none;
    }

    .info {
        text-align: left;
        padding: 10px 5px;
        font-weight: 400;
        font-size: 14px;
        color: #EEEEEE;
        font-style: normal;
        letter-spacing: 0;
        line-height: 19px;
        text-decoration: none;
    }

    .project-record-detail .card {
        background: #318EF5;
    }

    .info-title {
        padding: 10px 10px 5px;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        color: #AAA8A8;
        font-style: normal;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: none;
    }
</style>
